import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FetchLoginIncidents } from '../../../../redux/actions/LoginAction';
import { useDispatch } from 'react-redux';
import { setLocalStorage } from '../../../utils/helpers';
import { ApiUrl } from '../../../../redux/helpers/Endpoints';
import jwt_decode from 'jwt-decode';
import { AUTH_DATA } from '../../../utils/const';
import { useLocation } from 'react-router-dom';
import { LOGIN_INCIDENTS_SUCCESS } from '../../../../redux/constants/LoginConstants';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Icon, SvgIcon } from '@material-ui/core';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Login(props) {
  const [PType, setPType] = useState('password');
  const [showOTP, setShowOTP] = useState(false);
  const [userId, setUserId] = useState();
  const [turnstileToken, setTurnstileToken] = useState('');
  const [turnstileError, setTurnstileError] = useState('');
  const [turnstileLoaded, setTurnstileLoaded] = useState(false);
  
  const PARAMS = useQuery();
  const { intl, loginData } = props;
  const handleEyeToggle = () => {
    if (PType === 'password') {
      setPType('text');
    } else {
      setPType('password');
    }
  };

  const loginMessage = loginData && loginData.message ? loginData.message : '';
  const loginErrorMessage = loginData && loginData.error ? loginData.error : '';
  const loginStatus = loginData && loginData.status ? loginData.status : '';
  const userDetails = loginData && loginData.user ? loginData.user : {};
  const loginCode = loginData && loginData.code ? loginData.code : 0;
  const loginErrCode =
    loginData && loginData.error && loginData.error.code
      ? loginData.error.code
      : 0;
  const loginErrMsg =
    loginData && loginData.error && loginData.error.message
      ? loginData.error.message
      : '';
  const token =
    loginData && loginData?.token && loginData?.token ? loginData?.token : '';

  const [loading, setLoading] = useState(false);
  const [invalidCred, setInvalidCred] = useState('');
  const [UserOtp, setOtp] = useState('');
  // const [timer, setTimer] = useState(60);

  const dispatch = useDispatch();

  const initialValues = {
    email: '',
    password: '',
  };

  useEffect(() => {
    let interval;
    // if (showOTP && timer > 0) {
    //   interval = setInterval(() => {
    //     setTimer((prevTimer) => prevTimer - 1);
    //   }, 1000);
    // } else if (timer === 0) {
    //   // setShowOTP(false);
    // }

    return () => clearInterval(interval);
  }, [showOTP]);

  function handleResendOTP() {
    // setTimer(60); // Reset timer to 60 seconds
    GenerateOTP(userId?.id);
  }

  useEffect(() => {
    const token = PARAMS.get('token');
    if (token) {
      dispatch({
        type: LOGIN_INCIDENTS_SUCCESS,
        response: { token, status: true, message: 'Login success' },
      });
    }
  }, [PARAMS]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(' No password provided.'),
    otp: Yup.string().matches(/^\d{6}$/, 'OTP must be a 6-digit number'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };
  let dashboard;

  function GenerateOTP(uid) {
    const data = {
      uid: uid,
    };
    const headers = {
      Accept: '*/*',
      Authorization: token,
      'Content-Type': 'application/json',
    };

    axios
      .post(process.env.REACT_APP_DOMAIN + `/api/user/generate-otp`, data, {
        headers: headers,
      })
      .then(r => {
        if (r.data.status) {
          toast.success('OTP sent successfully');
        } else {
          toast.error('Some issue with sending OTP');
        }
      });
  }
  function ValidateOTP() {
    const data = {
      uid: userId?.id,
      otp: UserOtp,
    };
    const headers = {
      Accept: '*/*',
      Authorization: token,
      'Content-Type': 'application/json',
    };

    axios
      .post(process.env.REACT_APP_DOMAIN + `/api/user/validate-otp`, data, {
        headers,
      })
      .then(r => {
        if (r?.data?.status || r?.data?.message?.toLowerCase().includes('success')) {
          toast.success(r.data.message || 'OTP verified successfully');
          const decoded = jwt_decode(token);
          setLocalStorage(AUTH_DATA.AUTH, JSON.stringify(decoded));
          localStorage.setItem('token', token);
          fetchPackage();
        } else {
          setOtp('');
          toast.error(r.data.message || 'OTP verification failed');
        }
      })
      .catch(error => {
        setOtp('');
        toast.error(error.response?.data?.message || 'OTP verification failed');
      });
  }

  const fetchPackage = async () => {
    let packageURL = `${ApiUrl}/api/user/package`;
    const token = localStorage.getItem('token');
    const headers = {
      Accept: '*/*',
      Authorization: token,
      'Content-Type': 'application/json',
    };
    fetch(packageURL, { headers })
      .then(async response => {
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('dpackage', data[0]?.package_name);
          let url = `${ApiUrl}/api/user/dashboard_type?package_name=${encodeURIComponent(
            data[0].package_name
          )}`;
          fetch(url, { headers })
            .then(async response => {
              const data = await response.json();
              localStorage.setItem('dash', dashboard);
              if (
                data &&
                data.dashboardType &&
                data.dashboardType !== 'undefined'
              ) {
                // window.location
                dashboard = data.dashboardType[0];
                localStorage.setItem('dash', dashboard);
                if (dashboard === 'Visit') {
                  window.location = '/visit-dashboard';
                }

                if (dashboard === 'Click') {
                  window.location = '/click-dashboard';
                }

                if (dashboard === 'Event') {
                  window.location = '/event-dashboard';
                }

                if (dashboard === 'Install') {
                  window.location = '/install-dashboard';
                }

                if (dashboard === 'Reengagement_Events') {
                  window.location = '/app-reengagement-event-dashboard';
                }

                if (dashboard === 'App_Event') {
                  window.location = '/app-event-dashboard';
                }

                if (dashboard === 'Impression') {
                  window.location = '/impression-dashboard';
                }
              }
              if (dashboard === 'Mtrackit') {
                window.location = '/mtrackit_dashboard';
              }
              if (!dashboard) window.location = '/';
              if (!response.ok) {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
              }
            })
            .catch(error => {
              console.error('There was an error!', error);
            });
        } else {
          return Promise.reject(response.status);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };
  let TwoFactor = false;
  useEffect(() => {
    if (loginStatus && loginMessage === 'Login success') {
      if (token) {
        TwoFactor = jwt_decode(token);
        setUserId(jwt_decode(token));
        if (TwoFactor?.twoFA_flag) {
          GenerateOTP(TwoFactor?.id);
          setShowOTP(TwoFactor?.twoFA_flag);
        } else {
          const decoded = jwt_decode(token);
          setLocalStorage(AUTH_DATA.AUTH, JSON.stringify(decoded));
          localStorage.setItem('token', token);
          fetchPackage();
        }
      }
    } else if (!loginData.status && loginData.error && loginData.error.message)
      setInvalidCred(loginData.error.message);
  }, [loginData]);

  useEffect(() => {
    if (loginErrorMessage === 'Invalid email or password') {
      setInvalidCred(loginErrorMessage);
    }
  }, [loginErrorMessage]);

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!turnstileToken) {
        setTurnstileError('Please complete the Turnstile verification');
        setSubmitting(false);
        return;
      }
      enableLoading();
      localStorage.setItem('email', values.email);
      setTimeout(() => {
        dispatch(FetchLoginIncidents(values));
        disableLoading();
        setSubmitting(false);
      }, 1000);
    },
  });

  // Add Turnstile script to head
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setTurnstileLoaded(true);
      // Initialize Turnstile after script loads
      if (window.turnstile) {
        window.turnstile.render('#turnstile-widget', {
          sitekey: '0x4AAAAAABBXNTa5a18XM-kR',
          callback: (token) => {
            setTurnstileToken(token);
            setTurnstileError('');
          },
          'error-callback': () => {
            setTurnstileError('Failed to load Turnstile. Please try again.');
          }
        });
      }
    };
    script.onerror = () => {
      setTurnstileError('Failed to load Turnstile. Please try again.');
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      if (window.turnstile) {
        window.turnstile.remove('#turnstile-widget');
      }
    };
  }, []);

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      <Toaster position='top-right' />
      {/* begin::Head */}
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}

      {showOTP ? (
        <>
          <div className='form-group fv-plugins-icon-container text-center'>
            <div className='text-center mb-2 mb-lg-2'>
              <p className='font-weight-bold'>Enter 6 digit OTP</p>
              <p>{'Resend OTP in ' + '60'} seconds...</p>
            </div>
            <input
              type='number'
              className={`form-control form-control-solid h-auto py-5 px-6`}
              id='numberInput'
              value={UserOtp}
              onChange={e => setOtp(e.target.value)}
            />
          </div>
        </>
      ) : (
        <>
          <form
            onSubmit={e => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            className='form fv-plugins-bootstrap fv-plugins-framework'>
            <div className='form-group fv-plugins-icon-container text-center'>
              {invalidCred.length !== 0 ? (
                <h6 className='text-danger mb-5 text-center font-weight-bold'>
                  {invalidCred}
                </h6>
              ) : null}
              <input
                placeholder='Email'
                type='email'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'email'
                )}`}
                name='email'
                onKeyUp={() => {
                  // setInvalidCred('');
                  //  dispatch(clearLoginIncidents())
                }}
                {...formik.getFieldProps('email')}
              />

              {formik.touched.email && formik.errors.email ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className='form-group fv-plugins-icon-container'>
              <div style={{ position: 'relative' }}>
                <input
                  placeholder='Password'
                  type={PType}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    'password'
                  )}`}
                  onKeyUp={() => {
                    // setInvalidCred('');
                    //  dispatch(clearLoginIncidents())
                  }}
                  name='password'
                  {...formik.getFieldProps('password')}
                />
                <span
                  className='icontag'
                  style={{
                    position: 'absolute',
                    right: '10%',
                    transform: 'translate(0, -50%)',
                    top: '50%',
                  }}
                  onClick={handleEyeToggle}>
                  <SvgIcon
                    component={PType === 'text' ? VisibilityOff : Visibility}
                  />
                </span>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.password}</div>
                </div>
              ) : null}
            </div>

            <div className='form-group fv-plugins-icon-container'>
              <div className='turnstile-container text-center mb-3'>
                <div id="turnstile-widget"></div>
                {turnstileError && (
                  <div className="text-danger mt-2">{turnstileError}</div>
                )}
              </div>
            </div>

            <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
              <button
                id='kt_login_signin_submit'
                type='submit'
                disabled={formik.isSubmitting || !turnstileToken}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3 w-100`}>
                <span>Sign in</span>
                {loading && (
                  <span className='ml-3 spinner spinner-white'></span>
                )}
              </button>
            </div>
          </form>
        </>
      )}

      {showOTP ? (
        <div className='d-flex justify-content-between align-items-center'>
          <button
            onClick={handleResendOTP}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 w-50`}
          >
            <span>Resend OTP</span>
          </button>
          <button
            onClick={ValidateOTP}
            disabled={UserOtp?.length > 6 || UserOtp?.length < 6}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 w-50 ml-2`}
          >
            <span>Authenticate</span>
          </button>
          {UserOtp?.length > 6 && (
            <span className='ml-3 text-danger'>
              OTP must be a 6 digit number
            </span>
          )}
        </div>
      ) : null}

      {/*end::Form*/}
    </div>
  );
}

const mapStateToProps = state => {
  const { login } = state;
  return {
    loginData: login && login.login_data ? login.login_data : {},
  };
};

export default connect(mapStateToProps)(Login);